import React, { useEffect } from "react";
import classNames from "classnames";
import { useAppState } from "@state/state";
import Lockup from "@svg/lockup-large.svg";
import { IndexHero } from "@modules/indexes/index";
import { useContactPageData } from "@staticQueries";
import { SEO } from "@common";
import {
  Text,
  Icon,
  HeadingAccent,
  RichText,
  Button,
  AppLink,
  RandomShapes,
  Section,
  Container,
} from "@ui";

import { t } from "@translations";

/* eslint react/prop-types: 0 */

const ContactPage = () => {
  const { meta, hero, info, press } = useContactPageData();
  const [, dispatch] = useAppState();

  useEffect(() => {
    dispatch({
      type: "setHeaderTheme",
      headerTheme: {
        bgColor: "blue",
        showSearch: true,
        lockup: {
          size: "small",
          color: "blue",
        },
      },
    });
  }, []);

  const infoBlocks = [
    {
      id: 1,
      iconName: "email",
      label: t("email"),
      link: `mailto:${info.emailAddress}`,
      text: info.emailAddress,
    },
    {
      id: 2,
      iconName: "location",
      label: t("address"),
      link: null,
      text: info.location,
    },
    {
      id: 3,
      iconName: "social",
      label: t("follow us"),
      link: null,
      text: null,
      icons: info.socialMedia,
    },
  ];

  return (
    <>
      <SEO {...meta} />

      <IndexHero heading={hero.heading} description={hero.description} />

      <Section>
        <Container>
          <div className="md:flex md:justify-center md:-mx-3">
            {infoBlocks.map(
              ({ id, iconName, label, link, text, icons }, idx) => (
                <div
                  key={id}
                  className={classNames({
                    "w-full md:w-auto px-3 mb-6 md:mb-0": true,
                    "border-r-2 border-gray-1": idx < infoBlocks.length - 1,
                  })}
                >
                  <div className="md:px-6 lg:px-8">
                    <div className="flex items-center mb-2 md:mb-6">
                      <Icon name={iconName} className="w-6 h-6 mr-4" />
                      <Text
                        preset="label"
                        className="block font-bold uppercase text-blue"
                      >
                        {label}
                      </Text>
                    </div>

                    {link ? (
                      <AppLink
                        to={link}
                        target="_blank"
                        className="border-b-2 border-transparent hover:border-current transition sm:ml-10"
                      >
                        <span className="font-sans">{text}</span>
                      </AppLink>
                    ) : (
                      <div className="border-b-2 border-transparent sm:ml-10">
                        <span className="font-sans">{text}</span>
                      </div>
                    )}

                    {icons && (
                      <div className="flex sm:pl-10 -mx-2">
                        {icons.map(({ platform, link: _link }, _idx) => (
                          <AppLink
                            key={_idx}
                            to={_link.url}
                            target="_blank"
                            className="w-8 h-8 mx-2 text-gray hover:text-gray-4 transition"
                          >
                            <Icon name={platform} className="w-8 h-8" />
                          </AppLink>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              )
            )}
          </div>
        </Container>
      </Section>

      <Section className="bg-gray-1 sm:rounded-lg">
        <RandomShapes color="blue-2" />

        <Container className="relative z-10 md:flex md:flex-row-reverse">
          <div className="relative md:w-64 flex items-center justify-center py-12 mx-12 mb-6 md:py-0 md:mx-0 md:-my-6">
            <div
              className="absolute inset-0 bg-white opacity-40"
              style={{ borderRadius: "50%/65% 55% 40% 35%" }}
            />

            <span
              className="relative z-10 lockup-blue"
              style={{ width: "162.5px", height: "125px" }}
            >
              <Lockup />
            </span>
          </div>

          <div className="md:flex-1 md:pr-24 lg:pr-40">
            <HeadingAccent />
            <Text preset="h2" className="mb-4 text-blue">
              {press.heading}
            </Text>

            <RichText
              html={press.description.html}
              className="RichText--lg RichText--sans mb-4"
            />

            <AppLink to={press.ctaLink.url} target={press.ctaLink.target}>
              <Button
                preset="empty"
                text={press.ctaText}
                className="text-gray"
              />
            </AppLink>
          </div>
        </Container>
      </Section>
    </>
  );
};

export default ContactPage;
